.wpo-service-area,.wpo-service-s2,.service-style-3{
  padding: 100px 0 70px;
  background: #f5f5f5;
}
.wpo-section-title span{
    font-size: 16px;
    color: #009798;
}
.wpo-section-title h2{
    font-size: 36px;
    font-weight: 700;
    color: #333;
    padding-bottom: 20px;
    margin-bottom: 60px;
    position: relative;
}
.wpo-section-title h2:before {
    content: "";
    position: absolute;
    left: 47%;
    bottom: 0;
    width: 60px;
    height: 3px;
    background: #009798;
}
.wpo-service-single{
  overflow: hidden;
}
.wpo-service-single{
  position: relative;
  overflow: hidden;
  transition: all .3s;
  margin-bottom: 30px;
}


.wpo-service-area .wpo-service-item .wpo-service-single:before {
  content: none;
}
.wpo-service-s2  .wpo-service-item .wpo-service-single .wpo-service-icon{
    /*#background-color: rgba(127, 238, 31, 0.2);*/
    background-color: rgba(0, 151, 152, 0.36);
    width: 90px;
    height: 90px;
    line-height: 90px;
    text-align: center;
    border-radius: 50%;
    position: relative;
    margin: auto;
    margin-bottom: 25px;
}

.wpo-service-s2 .wpo-service-item .wpo-service-single .wpo-service-icon:before{
    content: "";
    background-color: rgba(52, 203, 255, 0.2);
    width: 100%;
    height: 100%;
    position: absolute;
    left: -5px;
    top: -5px;
    border-radius: 50%;
}


.wpo-service-s2 .wpo-service-item .wpo-service-single .wpo-service-icon:before{
    content: "";
    background-color: rgba(52, 203, 255, 0.08);
    width: 100%;
    height: 100%;
    position: absolute;
    left: -5px;
    top: -5px;
    border-radius: 50%;
}
.wpo-service-s2 .wpo-service-item .wpo-service-single .wpo-service-icon i, 
.service-style-3 .wpo-service-item .wpo-service-single .wpo-service-icon i {
    color: #142440;
}
.wpo-service-s2 .wpo-service-item .wpo-service-single h2,
.service-style-3 .wpo-service-item .wpo-service-single h2 {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 600;
}
.wpo-service-s2 .wpo-service-item .wpo-service-single p,
.service-style-3 .wpo-service-item .wpo-service-single p {
    line-height: 30px;
    color: #5d5b5b;
    margin-bottom: 0;
}


.wpo-service-s2 .wpo-service-item .wpo-service-single{
    background: #fff;
    text-align: center;
    padding: 40px 33px;
}
.wpo-service-s2 .wpo-service-single:before{
  display: none;
}