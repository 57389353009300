
.PxTrend-area {
    /*padding: 0 0;*/
    font-family: "Roboto", serif;
}

.pXcontainer {
    width: 100vw;
}



/*.pxTrendFeatureWrapper {*/
/*    position: absolute;*/
/*    right: 0;*/
/*    !*padding: 0 10px 0 100px;*!*/
/*}*/



.statement .h3 {
    font-family: "Roboto", serif;
    color: #faf9f9;
}


.introduction-picture {
    padding: 0px 10% 0px 10%;
    margin: -150px -5% 0px 0px;
}





h1 {
    font-size: 4rem;
}

h1::before,
h1::after {
    display: inline-block;
    content: "";
    border-top: .3rem solid black;
    width: 4rem;
    margin: 0 1rem;
    transform: translateY(-1rem);
}

.achievements {
    margin-top: 20px;
}

.achievements-area .pXdivider, .pXdivider {
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-align: center;
    padding: 0px 10px 20px;
    margin: 0 auto;
}

.achievements-area .pXdivider::before, .achievements-area .pXdivider::after, .pXdivider::before, .pXdivider::after{
    border-top-width: 1px;
    border-top-color: #29376c;
    border-top-style: solid;
    position: absolute;
    top: 30%;
    overflow: hidden;
    width: 50%;
    height: 1px;
    content: '';
    margin-top: 0;
}

.achievements-area .pXdivider::before, .pXdivider::before {
    margin-left: -50%;
}

.achievements-area .pXdivider .title, .pXdivider .title {
    font-family: "Roboto", serif;
    color: #29376c;
    font-size: 28px;
    font-weight: 400;
    padding: 0 15px;
}
