.contact-us-area{
  /*#background: url('../../images/air/air-fraight.jpg') no-repeat center top / cover;*/
  background: rgba(31, 53, 83, 0.8);
  position: relative;
  z-index: 1;
  padding: 30px 30px 20px;
}


.contact-phone:before {
  font-family: flaticon !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal !important;
  content: "\f10c";
  font-size: 30px;
}

.contact-mail:before {
  font-family: flaticon !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal !important;
  content: "\f10b";
  font-size: 30px;
}

.contact-location:before {
  font-family: flaticon !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal !important;
  content: "\f10d";
  font-size: 30px;
}

.contact-us-area:before{
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(31, 53, 83, 0.8);
  z-index: -1;
}
.contact-us-area h3{
  color:#fff;
  font-size:24px;
  font-weight: 600;
  margin-bottom: 30px;
  padding-bottom: 15px;
  position: relative;
}
.contact-us-area h3:before{
  content: "";
  position: absolute;
  left: 0;
  bottom:0;
  width: 30px;
  height: 5px;
  background:#009798ff;
}
.contact-s span{
  color:#fff;
  font-size: 15px;
}
.contact-s span i{
  padding-right:5px;
  color: #009798ff;
}
.contact-s p{
  color:#fff;
  font-weight: 18px;
}