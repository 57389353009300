.sliding-keys {
    padding: 10px 0 180px 0;
    margin-right: auto;
    margin-left: auto;
}

.fancy-item {
    color: #8d8d8d;
    padding: 30px 15px 10px 30px;
    border-left: 1px solid #ececec;
    background-position: center center;
}


.fade-item-0 {
    opacity: 0;
    animation: mymove 2s ease-out forwards;
    animation-delay: 0.0s;
}

.fade-item-05 {
    opacity: 0;
    animation: mymove 2s ease-out forwards;
    animation-delay: 0.5s;
}

.fade-item-1 {
    opacity: 0;
    animation: mymove 2s ease-out forwards;
    animation-delay: 1s;
}


@keyframes mymove {
    from {
        top: 100px;
        opacity: 0;
    }
    to {
        top: 0px;
        opacity: 1
    }
}

.fancy-item .title {
    font-family: "Roboto", serif;
    color: #3f3f3f;
    font-weight: 400;
    font-size: 20px;
}


.statement {
    font-family: "Roboto", serif;
    color: #d7d1d1;
    font-size: 18px;
    padding: 50px 15px 30px 30px;
    background-position: center center;
}

.statement .title {

    color: #faf9f9;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: .5rem;
}