.button {
    background-color: #fff;
    font-size: 15px;
    font-weight: 600;
    color: #29376c;
    padding: 10px 28px;
    /*border: 2px solid transparent;*/
    text-transform: capitalize;
}

.px-feature-button {
    background-color: #fff;
    font-size: 15px;
    font-weight: 600;
    color: #29376c;
    padding: 10px 28px;
    border: 2px solid transparent;
    text-transform: capitalize;
    display: inline-block;
    margin-top: 3px;
    cursor: pointer;
}

.px-test-button {
    background-color: black;
    color: white;
    font-size: 20px;
    padding: 10px 60px;
    border-radius: 5px;
    margin: 10px 0px;
    cursor: pointer;
}

.px-button-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;
}


.pxTrendFeatures {
    font-family: "Roboto", serif;
    color: #29376c;
    font-size: 18px;
    padding: 20px 15px 30px 30px;
    background-position: center center;
}

.pxTrendFeatures .title {
    font-family: "Roboto", serif;
    color: #29376c;
    font-weight: 400;
    font-size: 24px;
    margin-bottom: .5rem;
}