.partner-logo {
  padding: 10px 10px 10px 10px;
}
.partner-logo-marq {
  height: 100px;
  padding: 10px 10px 10px 10px;
}

.partner-img-marq{
  height: 100px;
  padding: 0px 30px;
}

.px-partner-area {
  padding: 20px 0 70px;
  background: #f5f5f5;
}
.pxPartner-tec {
  height: 100px;
  /*object-position: center;*/
  background-image:url("../../images/pxPartner/technord.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.link-spanner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.pxPartner-psa {
  height: 100px;
  /*object-position: center;*/
  background-image:url("../../images/pxPartner/psa.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}


.pxPartner-kmi {
  height: 100px;
  /*object-position: center;*/
  background-image:url("../../images/pxPartner/KMI.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.pxPartner-pv {
  height: 100px;
  /*object-position: center;*/
  background-image:url("../../images/pxPartner/profi-vision.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.pxPartner-dal {
  height: 100px;
  /*object-position: center;*/
  background-image:url("../../images/pxPartner/dal.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.pxPartner-xapi {
  height: 100px;
  /*object-position: center;*/
  background-image:url("../../images/pxPartner/xapi.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.pxPartner-pas {
  height: 100px;
  /*object-position: center;*/
  background-image:url("../../images/pxPartner/pas.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
