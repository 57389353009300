
.PxCrane-area{
  padding: 50px 0;
}

.slick-slide-title {
  text-transform: capitalize;
}

.PxTrend-item h3{
  font-size: 30px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 40px;
}
.PxTrend-item p{
  line-height: 30px;
  margin-bottom: 20px;
}
.PxTrend-item img{
  margin-bottom: 20px;
}

.pxtrend-slider{
  margin-bottom: 50px;
  /*height: 500px;*/
}

.pxtrend-slider .slide {
  height: 500px;
  position: relative;
  background-repeat: no-repeat;
  display: flex !important;
  justify-content: center;
  flex-direction: column;
}

.pxtrend-slider .slick-slide {
  text-align: center;
  position: relative;
}