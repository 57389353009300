/*------------------------------------------------------------------
[Master Stylesheet]
Theme Name:     Shifter – Transport and Logistics React Template
Version:        1.0.0
Author:         wpoceans
URL:            https://themeforest.net/user/wpoceans
-------------------------------------------------------------------*/

/*---------------------------
  Fonts
----------------------------*/
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/montserrat/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../fonts/montserrat/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/montserrat/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/montserrat/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/montserrat/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/montserrat/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/montserrat/montserrat-v25-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../fonts/montserrat/montserrat-v25-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/montserrat/montserrat-v25-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/montserrat/montserrat-v25-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/montserrat/montserrat-v25-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/montserrat/montserrat-v25-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/montserrat/montserrat-v25-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../fonts/montserrat/montserrat-v25-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/montserrat/montserrat-v25-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/montserrat/montserrat-v25-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/montserrat/montserrat-v25-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/montserrat/montserrat-v25-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/montserrat/montserrat-v25-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../fonts/montserrat/montserrat-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/montserrat/montserrat-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/montserrat/montserrat-v25-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/montserrat/montserrat-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/montserrat/montserrat-v25-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/roboto/roboto-v30-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../fonts/roboto/roboto-v30-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/roboto/roboto-v30-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/roboto/roboto-v30-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/roboto/roboto-v30-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/roboto/roboto-v30-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/roboto/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../fonts/roboto/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/roboto/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/roboto/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/roboto/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/roboto/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/roboto/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../fonts/roboto/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/roboto/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/roboto/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/roboto/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/roboto/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/roboto/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../fonts/roboto/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/roboto/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/roboto/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/roboto/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/roboto/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/*@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');*/
/*--------------------------------------------------------------
#0.1    Theme Reset Style
--------------------------------------------------------------*/

body {
  font-family: 'Roboto', sans-serif!important;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  color:#333;
}

@media (max-width: 767px) {
  body {
    font-size: 15px;
  }
}

p {
  color: #666;
  line-height: 30px;
  font-size:16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
  color: #353535;
}

ul {
  list-style-type: none;
  padding-left: 0;
  /*margin: 0!important;*/
  margin-bottom: 0;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

a:hover {
  text-decoration: none!important;
}

img {
  max-width: 100%;
}

button,
a:focus {
  outline: none;
}
.features-padding {
  padding: 100px 0;
}

@media (max-width: 991px) {
  .features-padding {
    padding: 90px 0;
  }
}

@media (max-width: 767px) {
  .features-padding {
    padding: 80px 0;
  }
}

.features-padding{
  padding: 100px 0;
}
.section-padding{
  padding: 100px 0;
}
.ptb-100-70{
  padding: 100px 0 70px;
}



.fixed-navbar {
  position: relative;
  z-index: 5555;
}
.fixed-navbar.active .header-style-1,
.fixed-navbar.active .header-style-2,
.fixed-navbar.active .header-style-3{
  position: fixed;
  z-index: 555;
  top: 0;
  left: 0;
  width: 100%;
  background: #101f3d;
  border: none;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, .1);
    -moz-box-shadow: 0px 1px 5px rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0px 1px 5px rgba(0, 0, 0, .1);
}
.fixed-navbar.active .header-style-2,
.fixed-navbar.active .header-style-3{
  background: #231b0e;
}