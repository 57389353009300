.wpo-section-area {
    background: #f5f5f5;
  }
  .wpo-section-icon {
      text-align: center;
      margin-top: -75px;
  }
  .wpo-section-content{
    text-align: center;
    padding: 30px 0 10px;
    transition: all .5s;
  }
  .wpo-section-item:hover .wpo-section-content{
    background:#009798;
    color:#fff;
  }
  .wpo-section-item:hover .wpo-section-content p a{
    color:#fff;
  }
  .wpo-section-item:hover .wpo-section-icon i.fi{
    background:#009798;
    color:#fff;
  }

  .wpo-section-item i.fi{
      color: black;
  }

  .wpo-section-content p{
    font-size: 24px;
    font-weight:400;
    transition: all .5s;
  }
  .wpo-section-content p a{
    color:#333;
    transition: all .5s;
  }
  .wpo-section-icon i.fi {
      background: #f5f5f5;
      padding: 47px 30px 30px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      transition: all .5s;
  }
  .col-d{
    padding: 0;
  }