@font-face {
    font-family: "flaticon";
    src: url("../fonts/flaticon.ttf?e9dd2e196090a3627ade9e546acc720b") format("truetype"),
url("../fonts/flaticon.woff?e9dd2e196090a3627ade9e546acc720b") format("woff"),
url("../fonts/flaticon.woff2?e9dd2e196090a3627ade9e546acc720b") format("woff2"),
url("../fonts/flaticon.eot?e9dd2e196090a3627ade9e546acc720b#iefix") format("embedded-opentype"),
url("../fonts/flaticon.svg?e9dd2e196090a3627ade9e546acc720b#flaticon") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    /*line-height: 1;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 50px;
}

.flaticon-left:before {
    content: "\f101";
}
.flaticon-right-arrow:before {
    content: "\f102";
}
.flaticon-dashboard:before {
    content: "\f103";
}
.flaticon-claw-machine:before {
    content: "\f104";
}
.flaticon-robotic-arm:before {
    content: "\f105";
}
.flaticon-clerk-with-tie:before {
    content: "\f106";
}
.flaticon-settings:before {
    content: "\f107";
}
.flaticon-technical-support:before {
    content: "\f108";
}
.flaticon-settings-1:before {
    content: "\f109";
}
.flaticon-customization:before {
    content: "\f10a";
}
.flaticon-email:before {
    content: "\f10b";
}
.flaticon-smartphone:before {
    content: "\f10c";
}
.flaticon-placeholder:before {
    content: "\f10d";
}
.flaticon-menu-button-of-three-horizontal-lines:before {
    content: "\f10e";
}