.slick-list {
    overflow:visible;
}

.slick-dots {
    margin-bottom: 30px;
}
.dark-blue {
    background: #29376c;
}


.modal-video-body {
    max-width: 1200px;
}

.slick-slide-title {
    text-transform: capitalize;
}

.slick-slide-image {
    width: auto;
    /*height:300px;*/
}

.modal-video-close-btn{
    top: 0px;
    right: 0px;
}